import * as React from 'react';
import { UpdateOrderForNewArrivalResponse } from 'api/simplyShip';

export interface TempOrderTotalsContextType {
  orderTotals: UpdateOrderForNewArrivalResponse;
  setOrderTotals: (value: UpdateOrderForNewArrivalResponse) => void;
}

const TempOrderTotalsContext = React.createContext<TempOrderTotalsContextType>(null);

export default TempOrderTotalsContext;
