import * as React from 'react';
import TempOrderTotalsContext from 'contexts/TempOrderTotalsContext/TempOrderTotalsContext';
import { UpdateOrderForNewArrivalResponse } from 'api/simplyShip';

const TempOrderTotalsContextContainer = ({ ...props }) => {
  const [orderTotals, setOrderTotals] = React.useState<UpdateOrderForNewArrivalResponse>(null);

  const contextValue = React.useMemo(
    () => ({
      orderTotals,
      setOrderTotals,
    }),
    [orderTotals, setOrderTotals],
  );

  return (
    <TempOrderTotalsContext.Provider value={contextValue}>{props.children}</TempOrderTotalsContext.Provider>
  );
};

export default TempOrderTotalsContextContainer;
