import * as React from 'react';
import camelCase from 'lodash-es/camelCase';
import startCase from 'lodash-es/startCase';
import css from './QuoteEditModal.scss';
import { Product } from 'styleguide/components/QuoteEditForm/Product';
import Modal from 'styleguide/components/Modal/Modal';
import { Catalog } from 'api/persephone/persephone';
import { KeyVal } from 'libs/utils/common-types';
import QuoteEditForm from 'styleguide/components/QuoteEditForm/QuoteEditForm';
import Span from 'styleguide/components/Span/Span';
import CustomQuoteWarningModal from 'styleguide/components/CustomQuoteWarning/CustomQuoteWarningModal';
import QuoteContextContainer from 'contexts/QuoteContext/QuoteContextContainer';
import Footer from './Footer/Footer';
import { FormikFieldWrapper } from 'styleguide/components/Formik';
import keys from 'lodash-es/keys';
import { Order } from 'api/orders/types';
import YvantageUserContext from 'contexts/YVantageUserContext/YvantageUserContext';
import { type OrderDispatchType } from 'app/contexts/OrderContext/actions';
import AppContext from 'contexts/AppContext/AppContext';
import { Formik } from 'formik';

interface Props {
  lineItemId?: number;
  alias: string;
  onClose: () => void;
  onCustomizeClose?: () => void;
  initialValues?: KeyVal;
  initialPrice?: string;
  catalog: Catalog;
  doUpdateLineItem: (
    dispatch: OrderDispatchType,
    orderNumber: string,
    id: number,
    quoterJson: KeyVal,
    alias: string,
  ) => Promise<void>;
  doCreateLineItem?: (
    dispatch: OrderDispatchType,
    orderNumber: string,
    quoterJson: KeyVal,
    alias: string,
  ) => Promise<boolean>;
  doCustomizeLineItem?: (dispatch: OrderDispatchType, orderNumber: string, id: number) => Promise<void>;
  productionDays: number;
  order?: Order;
  dispatch: OrderDispatchType;
  displayProductsSelect?: boolean;
}

type AliasedOptions = {
  key: string;
  alias: string;
  label: string;
};

const QuoteEditModal = ({
  catalog,
  lineItemId,
  onClose,
  onCustomizeClose,
  initialValues,
  initialPrice,
  doUpdateLineItem,
  doCreateLineItem,
  doCustomizeLineItem,
  productionDays,
  order,
  dispatch,
  ...props
}: Props) => {
  const [product, setProduct] = React.useState(
    new Product(catalog[camelCase(initialValues ? initialValues.product : 'color-copies')]),
  );
  const [alias, setAlias] = React.useState(initialValues ? props.alias : 'color-copies');
  const [customQuoteModalOpened, setCustomQuoteModalOpened] = React.useState<boolean>(false);
  const yvUserContext = React.useContext(YvantageUserContext);
  const appContext = React.useContext(AppContext);

  const productOptions = React.useMemo((): AliasedOptions[] => {
    const options: AliasedOptions[] = [];
    const aliasOptions: AliasedOptions[] = [];

    keys(catalog)
      .filter(key => appContext.store.products.find(p => camelCase(p.slug) === key))
      .forEach(key => {
        catalog[key].productAliases.forEach(productAlias => {
          let name = catalog[key].name;

          if (key !== camelCase(productAlias.key)) {
            name = `${productAlias.name} (${catalog[key].name})`;
            aliasOptions.push({
              key,
              label: name,
              alias: productAlias.key,
            });
          } else {
            options.push({
              key,
              label: name,
              alias: productAlias.key,
            });
          }
        });
      });

    const sortedOptions = [
      ...options.sort((a, b) => a.label.localeCompare(b.label)),
      ...aliasOptions.sort((a, b) => a.label.localeCompare(b.label)),
    ];
    return sortedOptions;
  }, [catalog, appContext.store.products]);

  const onSubmit = (values: KeyVal) => {
    if (lineItemId) {
      doUpdateLineItem(dispatch, order ? order.number : null, lineItemId, values, alias).then(() => {
        onClose();
      });
    } else {
      doCreateLineItem(dispatch, order.number, values, alias).then(() => {
        onClose();
      });
    }
  };

  const onCustomize = () => {
    doCustomizeLineItem(dispatch, order.number, lineItemId).then(() => {
      onCustomizeClose();
    });
  };

  const title = startCase(alias) || product.getProductName();

  return (
    <QuoteContextContainer
      productSlug={product.getProductKey()}
      alias={alias}
      initialPrice={initialPrice ? parseFloat(initialPrice) : null}
      initialValues={initialValues || product.getKeyedObjectWithDefaultValues(alias)}
      onChange={() => {}}
      onSubmit={onSubmit}
      onCustomize={onCustomize}
      canCustomize={!!lineItemId}
      productionDays={productionDays}
      displayNotes
      showHiddenOptions={yvUserContext && yvUserContext.currentYvUser.showHiddenOptions}
      instantQuote
    >
      <Modal
        onClose={onClose}
        shown
        className={css.modal}
        title={
          <div className={css.titleWrapper} data-cy="editFormTitle">
            <Span className={css.editing}>Editing</Span>
            {lineItemId ? (
              <Span className={css.title}>{title}</Span>
            ) : (
              <Formik
                initialValues={{
                  newProductPicker: camelCase(initialValues ? initialValues.product : 'color-copies'),
                }}
                onSubmit={() => {}}
              >
                {() => (
                  <FormikFieldWrapper
                    name="newProductPicker"
                    onChange={(item: AliasedOptions) => {
                      const newProduct = new Product(catalog[item.key]);
                      setProduct(newProduct);
                      setAlias(item.alias);
                    }}
                    componentType="combobox"
                    labelComponent=""
                    options={productOptions}
                  />
                )}
              </Formik>
            )}
          </div>
        }
        footer={
          <Footer
            lineItemName={title}
            onClose={onClose}
            setCustomQuoteModalOpened={setCustomQuoteModalOpened}
          />
        }
      >
        {customQuoteModalOpened && (
          <CustomQuoteWarningModal onClose={() => setCustomQuoteModalOpened(false)} />
        )}
        <QuoteEditForm />
      </Modal>
    </QuoteContextContainer>
  );
};

export default QuoteEditModal;
