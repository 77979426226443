import { AvailableDates } from './types';
import find from 'lodash-es/find';
import { format, isAfter, isBefore } from 'date-fns';

export const getSameOrNextClosestDate = (selectedDate: Date, availableDates: AvailableDates): Date => {
  if (selectedDate === null || availableDates === null) {
    return null;
  }

  const selectedDateString = format(selectedDate, 'M/d/yyyy');
  const freeShippingDates = availableDates.freeShippingDates ? availableDates.freeShippingDates : [];
  const normalDates = availableDates.normalDates;

  if (find(freeShippingDates, elem => elem === selectedDateString)) {
    return selectedDate;
  }

  if (find(normalDates, elem => elem === selectedDateString)) {
    return selectedDate;
  }

  const firstDay = new Date(normalDates[0]);
  const lastDay =
    freeShippingDates.length > 0
      ? new Date(freeShippingDates[freeShippingDates.length - 1])
      : new Date(normalDates[normalDates.length - 1]);

  if (isAfter(selectedDate, lastDay)) {
    return lastDay;
  }

  if (isBefore(selectedDate, firstDay)) {
    return firstDay;
  }

  throw new Error(`Can't find next or prev date for ${format(selectedDate, 'M/d/yyyy')}`);
};
