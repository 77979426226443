/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import * as React from 'react';
import cn from 'classnames';
import { DayTypeToLabel, DayType, BgColors } from '../constants';

interface Props {
  className?: string;
  dayType: DayType;
}

const LegendItem = ({ className, dayType }: Props) => (
  <div
    className={cn(
      className,
      'flex items-center rounded-full border border-neutral-200 bg-neutral-50 p-[5px] px-2',
    )}
  >
    <div className="text-xs font-medium text-gray-500">{DayTypeToLabel[dayType]}</div>
    <div className={cn(BgColors[dayType], `ml-1 h-[6px] w-[6px] rounded-full`)} />
  </div>
);

export default LegendItem;
