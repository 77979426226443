/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import * as React from 'react';
import cn from 'classnames';

interface Props {
  headerClassName?: string;
  header?: React.ReactNode;
}

const Header = ({ header, headerClassName }: Props) => (
  <div
    className={cn(
      `flex flex-wrap justify-center rounded-t-xl text-sm leading-6 text-default shadow-lg -md:max-w-[313px]`,
      headerClassName,
    )}
  >
    {!!header && header}
  </div>
);

export default Header;
