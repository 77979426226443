import { AvailableDates } from 'app/contexts/OrderContext/types';

export interface AvailableDatesNormalized {
  minDate: Date;
  maxDate: Date;
  freeShippingDates: Date[];
  rushCriticalDates: Date[];
  normalDates: Date[];
}

export const normalizeAvailableDates = (dates: AvailableDates): AvailableDatesNormalized => {
  const {
    freeShippingDates: freeShippingDatesStr,
    rushCriticalDates: rushCriticalDatesStr,
    normalDates: normalDatesStr,
  } = dates;

  const freeShippingDates = (freeShippingDatesStr || []).map(date => new Date(date));
  const rushCriticalDates = (rushCriticalDatesStr || []).map(date => new Date(date));
  const normalDatesRaw = normalDatesStr.map(date => new Date(date));

  // dates without time
  const freeCriticalDateStr = [...freeShippingDates, ...rushCriticalDates].map(date => date.toDateString());
  const normalDates = normalDatesRaw.filter(date => !freeCriticalDateStr.includes(date.toDateString()));

  // calculate new min and max dates from the available dates
  const minDate = new Date(
    Math.min(...[...freeShippingDates, ...rushCriticalDates, ...normalDates].map(date => date.getTime())),
  );
  const maxDate = new Date(
    Math.max(...[...freeShippingDates, ...rushCriticalDates, ...normalDates].map(date => date.getTime())),
  );

  return {
    minDate,
    maxDate,
    freeShippingDates,
    rushCriticalDates,
    normalDates,
  };
};
