/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';

export enum DayType {
  STANDARD = 'STANDARD',
  CRITICAL = 'CRITICAL',
  FREE_SHIPPING = 'FREE_SHIPPING',
}

export const BgColors = {
  [DayType.STANDARD]: 'bg-blue-500',
  [DayType.CRITICAL]: 'bg-orange-700',
  [DayType.FREE_SHIPPING]: 'bg-green-500',
};

export const DayTypeToLabel = {
  [DayType.STANDARD]: 'Available Delivery Dates',
  [DayType.CRITICAL]: 'Rush Critical',
  [DayType.FREE_SHIPPING]: 'Free Delivery',
};

export const DayTypeToShortLabel = {
  [DayType.STANDARD]: 'Delivery Date',
  [DayType.CRITICAL]: 'Rush Critical',
  [DayType.FREE_SHIPPING]: 'Free Delivery!',
};
