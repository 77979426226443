/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import * as React from 'react';
import { format } from 'date-fns';
import cn from 'classnames';

import Button from 'styleguide/components/Button/Button';
import { DayType, DayTypeToShortLabel } from '../constants';
import LegendItem from './LegendItem';

interface Props {
  headerClassName?: string;
  showSelectButton?: boolean;
  selectedDay?: Date;
  onSelectTimeClick: () => void;
  selectedDayType: DayType;
  showFreeShippingDates: boolean;
  showCriticalDates: boolean;
  showStandardDates: boolean;
  twoMonths?: boolean;
}

const classNameFromDayType = (dayType: DayType) => {
  switch (dayType) {
    case DayType.STANDARD:
      return 'text-blue-500';
    case DayType.CRITICAL:
      return 'text-orange-500';
    case DayType.FREE_SHIPPING:
      return 'text-green-500';
    default:
      return '';
  }
};

const Footer = ({
  selectedDay,
  showSelectButton,
  onSelectTimeClick,
  selectedDayType,
  showCriticalDates,
  showFreeShippingDates,
  showStandardDates,
  twoMonths,
}: Props) => (
  <div
    className={`flex w-full ${
      twoMonths ? 'flex-row -md:flex-col -md:py-0' : 'flex-col py-0'
    }  justify-between rounded-b-xl bg-white p-4 text-sm leading-6 text-default shadow-lg `}
  >
    <div
      className={`flex w-full flex-wrap flex-row items-center justify-start -md:w-full gap-y-2 ${
        twoMonths ? '-md:w-full -md:justify-between -md:py-4' : 'w-full justify-between py-[16px]'
      } -md:py-[16px]`}
    >
      {showFreeShippingDates && <LegendItem dayType={DayType.FREE_SHIPPING} className="mr-2" />}
      {showStandardDates && <LegendItem dayType={DayType.STANDARD} className="mr-2" />}
      {showCriticalDates && <LegendItem dayType={DayType.CRITICAL} className="mr-2" />}
    </div>
    <div
      className={`flex justify-end self-center ${
        twoMonths
          ? 'w-1/2 -md:mb-3 -md:w-full -md:justify-between -md:pb-[16px]'
          : 'mb-3 w-full justify-between pb-[16px]'
      } `}
    >
      <div className="mr-4 flex shrink-0 flex-col justify-center">
        {selectedDayType && (
          <strong className={cn(classNameFromDayType(selectedDayType), 'text-xs font-bold leading-4')}>
            {`${DayTypeToShortLabel[selectedDayType]}`}
          </strong>
        )}
        <strong className="text-sm font-bold leading-5">{format(selectedDay, 'LLL do yyyy')}</strong>
      </div>
      {!!showSelectButton && (
        <Button
          onClick={onSelectTimeClick}
          type="button"
          color="blue"
          disabled={!selectedDay}
          className="!text-sm"
        >
          Select Time
        </Button>
      )}
    </div>
  </div>
);

export default Footer;
